import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
// import './registerServiceWorker'
import '@/assets/styles/reset.scss'
import common from '@/assets/js/common.js'
import { Notify, Dialog } from 'vant'
import vueTouch from "vue-plugin-touch"
import touch from 'vue-directive-touch'
import VueI18n from 'vue-i18n'
import 'lib-flexible'
import 'vant/lib/index.css';
Vue.use(Notify).use(Dialog).use(vueTouch).use(touch).use(VueI18n);
Vue.prototype.common=common;

const i18n = new VueI18n({
    locale: localStorage.getItem("lang") || "ar_iq",
    messages: {
      'zh_cn': require('./assets/languages/zh_CN.json'),
	  'en_us': require('./assets/languages/en_US.json'),
	  'ar_iq': require('./assets/languages/ar_IQ.json')
    }
});

if (localStorage.getItem("lang") == 'ar_iq') {
    document.documentElement.setAttribute('dir', 'rtl');
} else {
    document.documentElement.setAttribute('dir', 'ltr');
}

Vue.config.productionTip = false;

window.vm = new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app');
